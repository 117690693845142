html {
  /* height: 100%; */
  margin: 0;
  background-color: #faf9ff;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


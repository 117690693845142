body {
  width: 100vw;
  height:auto;
}

/* #root {
  width:100%;
  min-width:100%;
  min-height: 100%;
} */

p {
  font-size: 16px;
  font-family: "Biryani", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.reneimg {
  border-radius: 2%;
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  top:0;
  left:0;
}

.container{
  width: 100%;
  display: grid;
}

#rene-container{
  width: 100%;
  height: 200px;
  padding-top: 40%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  font-size: 15px;
}

li a {
  display:block;
  color: #896bff;
  text-decoration: none;
}

li a:hover {
  font-weight: 900;
}

#nav-container-solid, #nav-container{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 500;
  
}

#nav-container-solid {
  background-color: #896bff;
}

#nav-container {
  background-color: #faf9ff;
}


#nav-container-solid li a, #nav-container-solid h1 a {
  display:block;
  color: white;
  text-decoration: none;
  align-items: center;
}


#nav-container-solid button p a {
  color: #896bff;
}

#nav-container li,
#nav-container-solid li {
  float: left; 
}


#nav-container h1 a, #nav-container-solid h1 a  {
  font-size: 25px;
  text-decoration: none;
}

#nav-container h1 a  {
  color: #896bff;
}

#nav-container-solid h1 a {
  color: white;
}

#nav-btn-solid, #nav-btn{
  width: 45%;
  display: flex;
  justify-content: end;
  padding: 10px;
 
}

#nav-btn p, #nav-btn-solid p {
  font-size: 14px;
}

#nav-btn button {
  background-color: #faf9ff;
  height: 20%;
  width: 50%;
}

#nav-btn-solid button {
  width: 50%;
  height: 20%;
}

#nav-left{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 50%;
  gap:30px;
  padding-left: 20px;
}

.nav-links {
  width: auto;
  display: inline-block;
  align-items: center;
  gap: 10px;
  position: sticky;
  padding: 10px;
}

.nav-links ul{
  display: flex;
  gap: 20px;
  align-items: center;
  width: auto;
  flex-wrap: wrap;
  
}

.sticky{
  position: sticky;
  top: 0 ;
  z-index: 1000;
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
}


button {
  background-color: #896bff;
  border-radius: 100px;
  border: none;
  transition-duration: .4s;
  font-size: 20px;
  color: white;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  min-height: 48px;
  min-width: 48px;
  cursor: pointer;
}

.bg button {
  font-size: 15px;
  padding: 0px 20px 0px 20px;
  margin-top:10px;
}

button:hover {
  background-color: #149678;
  color: white;
}

button a {
  color: white;
  text-decoration: none;
}

section {
    background-color: #faf9ff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0px 20px 0px;
    width:100vw;
}

section.half {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-right:auto;
  margin-left:auto;
  width:60vw;
  padding: 0;
  align-items: start;
  gap: 100px;
  margin-top: 40px;
}

.profile{
  width: 30%;
  position: sticky;
  height: 100vh;
  top:90px;
}

.profile h4{
  font-weight: 200;
}

.right{
  width: 60%;
}

section.about{
  background-color: #faf9ff;
  width:95%;
  align-items: start;
  justify-content: start;
  padding:0px;
  margin-bottom: 50px;
  
}

section.about p {
  text-align: start;
}

section.about > div {
  background-color: #faf9ff;
}



section.profile{
  background-color: #faf9ff;
  width:100%;
  align-items: start;
  justify-content: start;
  padding:0px
}

section.profile > div h3{
  line-height: 10px;
}



section.home {
  background-color: #faf9ff;
  width:100vw;
}

.home-text{
  margin-right: auto;
  margin-left: auto;
  width: 60%;
  margin-top:20px;
  margin-bottom:20px;
}

section.projects {
  background-color: #faf9ff;
  margin-right:auto;
  margin-left:auto;
  display: flex;
  width: 100%;
  padding:0px;
}

section.projects h3{
  text-align: center;
}

section.projects h3.heading{
  text-align: start;
}

@media(max-width: 768px){
  h3, #modal-left h3 {
    font-size: .9rem;
    text-align: center;
    color: #7055f8;
  }

  p {
    font-size: .6rem;
  }

  .reneimg {
    border-radius: 2%;
    max-width: 100%;
    max-height: fit-content;
    object-fit: cover;
    position: absolute;
    top:0;
    left:0;
  }

  h2 {
    font-size: .8rem;
    margin:0;
    color: #7055f8;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    
  }

}

h2 {
  font-size: 57px;
  margin:0;
  color: #7055f8;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  
}

h3 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.heading{
  color: #896bff;
}

#modal-left h3 {
  font-size: 30px;
  text-align: center;
  color: #7055f8;
}

#modal-left h3 {
  text-align: start;

}

h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.name-tag {
  margin-bottom: 0px;
  margin-top: 100px
}

.center{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  margin-left:10px;
  margin-bottom: 30px;
  flex-direction: column;
}

.center p {
  line-height: 28px;
}

#tagline {
  width: 50%;
  margin-right:auto;
  margin-left: auto;
}

#link{
  font-size: xx-small;
  color: white;
}

#carousel-text{
  margin-top: 10px;
  font-size: 18px
}

.logo{
  height: 150px;
  
}

.companies{
  width:100%;
  height:200px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.company-image{
  max-width: 50%;
}

.company-image img{
  width: 100%;
  object-fit: scale-down;
  
}

#card{
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: rgb(233, 241, 228); 
  top: 50px;
  padding: 1rem;
  margin: 10px;
  width: 100%;
}

.card-name{
  width: 20%;
}

#card > div > p {
  font-size: 13px;
  line-height: 150%;
}

.cards{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

#project-btn button{
  font-size: small;
  padding: 0 20px;
}

.card-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

.card-width {
  width: 100%;
}

section.resume{
  background-color: #faf9ff;
  max-width:100%;
  align-items: start;
  justify-content: start;
  padding:0px;
  margin-top: 50px;
  margin-bottom: 80px;
}

.resume > div h3{
  text-align: center;
}


#bio{
  display: flex;
  flex-direction: column;
  width: 50%;
}


.bio-right p, .bio-right h2{
  text-align: start;
}

#bio p, #bio h3 {
  text-align: start;
}

.contact-btn{
  margin-top: 20px;
}

.contact-btn button{
  padding: 0px 20px 0px 20px;
}

#numbers{
  background-color: #e3dcf9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#numbers h3{
  margin-bottom: 0px;
}

#numbers p{
  margin-bottom: 0px;
  text-align: center;
  font-size: 13px;
}

.bg{
  background-color: #e3dcf9;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.modal{
  display:block;
  position:fixed;
  z-index: 1;
  left: 0;
  top:0;
  width: 100%;
  height:100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4); 
}

.modal-content{
  background-color: #fefefe;
  margin: 5% auto;
  border: 1px solid #888;
  width: 80%;
  display: flex;
  flex-direction: row;
  padding: 50px 30px 50px 50px;
  flex-direction: column;
  background-color: #f5f4f8;  
}

.modal-top{
  display: flex;
}

#modal-left{
  width: 50%;
  padding:0px 20px 0px 0px;
  margin: 60px 0px 0px 0px;
}


#modal-left h4 {
  text-align: start;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5em;
}

#modal-left p {
  text-align: start;
  padding:0;
  font-size: 14px;
}


#modal-right{
  width: 100%;
  display: flex;
  flex-direction: column;
}

#modal-right img{
  height: 400px;
  max-width: 90%;
  object-fit: scale-down;
  background-color: #f5f4f8;
}

.close {
  cursor: pointer;
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

#features ul li {
  font-size: 15px;
  font-family: "Biryani", sans-serif;
  font-weight: 400;
  font-style: normal;
}

#tech p {
 text-align: start;
 padding:0;
}

.project-links{
  display:flex;
  justify-content: end;
  align-items: end;
  gap: 20px;
}

i{
  font-size: 30px;
  color:black;
}

i:hover{
  font-size: 30px;
  color:#7055f8;
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  background-color: black;
  color:white;
  text-align: center;
  border-radius: 6px;
  padding: 5px ;
  position: absolute;
  z-index: 1;
  font-size: 15px;
  font-style: normal;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; 
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

a {
  cursor: pointer;
  font-size: 14px;
}

#features {
  margin-top: 50px;
}

#features-bg{
  background-color: #7055f8;
}

#features-bg h4{
  color: white;
}

.features-list{
 padding: 0 30px;
}

@counter-style thumbs {
  system: cyclic;
  symbols: "\1F44D";
  suffix: " "
}

.features-list ul{
  list-style-type: thumbs;
}

.bold {
  font-weight: bold;
}

.number{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33%;
  padding: 1rem;
}

#icons i{
  margin-right: 20px;
  margin-top: 50px;
}


@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");